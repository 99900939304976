import React from 'react';
import Button from '@components/Button';
import WistiaVideoEmbed from '@components/WistiaVideoEmbed';
import { PrismicHomepageDataBodyHomepageHero } from '@typings/prismic-types';
import * as styles from './styles.module.scss';

interface Props {
  slice: PrismicHomepageDataBodyHomepageHero;
}

export default function HomepageHero({ slice }: Props) {
  return (
    <section className="pt-10 pb-24">
      <div className="flex flex-col items-center text-center mb-32">
        <h1 className="marketing-h1-small md:marketing-h1 mb-10">
          {slice.primary.section_title.text || ``}
        </h1>
        <h3
          className="marketing-xlarge-p mb-14"
          dangerouslySetInnerHTML={{
            __html: slice.primary.text.text,
          }}
        />
        <Button
          type={'Primary'}
          iconType={'Arrow'}
          href={slice.primary.cta_link.url}
        >
          {slice.primary.cta_text.text}
        </Button>
      </div>
      <div className={styles.heroVideo}>
        <WistiaVideoEmbed videoUrl={slice.primary.video_link.url} />
      </div>
    </section>
  );
}
